* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 50%;
  min-width: 250px;
  padding: 20px 0px;
  top: 10%;
  left: 25%;
  position: absolute;
  margin-left: -5%;
  box-shadow: 20px 20px 20px 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.item:hover {
  background-color: rgb(135, 235, 54);
  cursor: pointer;
  padding: 1px;
  border-radius: 3px;
}
